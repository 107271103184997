<template>
    <div>
        <b-overlay :show="showLoader" variant="transperant" blur="5px" opacity="1" spinner-variant="primary">
            <b-row>
                <b-col lg="4" md="4" sm="6">
                    <statistic-card-with-line-chart icon="SlidersIcon" color="warning" :statistic="intents.length"
                        statistic-title="INTENTS"
                        :chart-data="[{ name: 'intents', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col lg="4" md="4" sm="6">
                    <statistic-card-with-line-chart icon="ListIcon" :statistic="patterns" statistic-title="PATTERNS"
                        :chart-data="[{ name: 'patterns', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col lg="4" md="4" sm="6">
                    <statistic-card-with-line-chart icon="CornerUpLeftIcon" color="success" :statistic="responses"
                        statistic-title="RESPONSES"
                        :chart-data="[{ name: 'responses', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
            </b-row>

            <b-card no-body>
                <b-card-header>
                    <b-card-title>intents</b-card-title>
                    <div class="ml-auto">
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-warning"
                            class="btn-icon mr-25" @click="testModal = true">
                            Test Assistant
                            <feather-icon icon="MessageSquareIcon" />
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-success"
                            class="btn-icon ml-25 mr-25" @click="trainModal = true">
                            Train Assistant
                            <feather-icon icon="CpuIcon" />
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="btn-icon ml-25"
                            @click="addModalShow = true">
                            Add New Intent
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </div>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions" :clearable="false"
                                class="per-page-selector d-inline-block mx-50" />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col cols="12" md="4">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table hover responsive class="position-relative" :per-page="perPage"
                            :current-page="currentPage" :items="intents" :fields="fields" :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة" :filter-included-fields="filterOn"
                            @filtered="onFiltered">

                            <template #cell(patterns)="data">
                                <b-badge variant="light-primary">
                                    {{ data.item.patterns.length }} Patterns
                                </b-badge>
                            </template>
                            <template #cell(responses)="data">
                                <b-badge variant="light-primary">
                                    {{ data.item.responses.length }} Responses
                                </b-badge>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="primary">
                                    <feather-icon icon="ClockIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).format('llll') }}</span>
                                </b-badge>
                            </template>

                            <template #cell(action)="data">
                                <div class="float-right">
                                    <b-button @click="deleteIntent(data.item.id)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger"
                                        class="btn-icon mr-1">
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                    <b-button @click="editModal(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-secondary" class="btn-icon">
                                        <feather-icon icon="SettingsIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0" pills first-number last-number prev-class="next-item"
                            next-class="prev-item" />
                    </b-col>
                </b-row>
            </b-card>

            <!-- add modal -->
            <b-modal id="modal-2" ref="modal-add" size="lg" v-model="addModalShow" title="Create intent" ok-title="Send"
                cancel-title="Cancel" cancel-variant="outline-secondary" @hidden="resetAddModal" @ok="handleOkAddModal"
                no-close-on-backdrop>
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Intent Name" label-for="name">
                                    <validation-provider #default="{ errors }" name="Intent Name" rules="required">
                                        <b-form-input v-model="intent.tag" :state="errors.length > 0 ? false : null"
                                            placeholder="Intent Name" id="name" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <div class="border rounded p-1 mb-1">
                                    <b-card-text>
                                        <h5>PATTERNS</h5>
                                    </b-card-text>
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group label="Intent Pattern" label-for="name">
                                                <validation-provider #default="{ errors }" name="Intent Pattern"
                                                    rules="">
                                                    <b-input-group>
                                                        <b-form-input @keyup.enter="appendPattern" v-model="intent.text"
                                                            placeholder="put text then enter" />
                                                        <b-input-group-append is-text>
                                                            <feather-icon class="cursor-pointer" icon="CornerUpLeftIcon"
                                                                @click="appendPattern" />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12">
                                            <b-list-group>
                                                <b-list-group-item
                                                    v-for="pattern in intent.patterns" :key="pattern.id"
                                                    class="d-flex justify-content-between align-items-center">
                                                    <span>{{pattern.text}}</span>
                                                    <b-badge @click="deletePattern(pattern.id)" variant="danger" pill class="badge-round cursor-pointer">
                                                        x
                                                    </b-badge>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                            <b-col cols="12">
                                <div class="border rounded p-1 mb-1">
                                    <b-card-text>
                                        <h5>RESPONSES</h5>
                                    </b-card-text>
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group label="Intent Response" label-for="name">
                                                <validation-provider #default="{ errors }" name="Intent Response"
                                                    rules="">
                                                    <b-input-group>
                                                        <b-form-input @keyup.enter="appendResponse"
                                                            v-model="intent.text2" placeholder="put text then enter" />
                                                        <b-input-group-append is-text>
                                                            <feather-icon class="cursor-pointer" icon="CornerUpLeftIcon"
                                                                @click="appendResponse" />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12">
                                            <b-col cols="12">
                                                <b-list-group>
                                                    <b-list-group-item
                                                        v-for="response in intent.responses" :key="response.id"
                                                        class="d-flex justify-content-between align-items-center">
                                                        <span>{{response.text}}</span>
                                                        <b-badge @click="deleteResponse(response.id)" variant="danger" pill class="badge-round cursor-pointer">
                                                            x
                                                        </b-badge>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-col>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>

                <template #modal-footer>
                    <b-button variant="outline-secondary" class="mr-1" @click="resetAddModal">
                        Cancel
                    </b-button>
                    <b-button variant="primary" :disabled="loading" @click="handleOkAddModal" class="mr-1">
                        <template v-if="!loading">
                            Save
                        </template>
                        <template v-else>
                            <b-spinner small />
                        </template>
                    </b-button>
                </template>
            </b-modal>
        </b-overlay>

        <!-- training modal -->
        <b-modal
            v-model="trainModal"
            no-close-on-backdrop
            id="modal-1"
            title="Train Assistant"
            ok-only @ok="trainModel"
            ok-title="train"
        >
            <validation-observer ref="simpleRules2">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Language" label-for="language">
                                <validation-provider #default="{ errors }" name="Language" rules="required">
                                    <v-select
                                        id="language"
                                        v-model="train.language"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="name"
                                        :reduce="item => item.code"
                                        :options="languages"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- test modal -->
        <b-modal
            v-model="testModal"
            no-close-on-backdrop
            id="modal-1"
            title="Test Assistant"
            ok-only @ok="testModel"
            ok-title="done"
        >
            <validation-observer ref="simpleRules3">
                <b-form @submit.prevent.capture="testModel">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Message" label-for="message">
                                <validation-provider #default="{ errors }" name="Message" rules="required">
                                    <b-form-input @keyup.enter="testModel" v-model="test.message" :state="errors.length > 0 ? false : null"
                                        placeholder="Enter Message" id="message" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-alert
                                variant="secondary"
                                :show="test.response != '' && test.response != null && test.response != undefined"
                            >
                                <h4 class="alert-heading">
                                    <feather-icon class="mr-25" icon="CornerUpLeftIcon"/>
                                    Response
                                </h4>
                                <div class="alert-body">
                                    <span>{{ test.response }}</span>
                                </div>
                            </b-alert>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
    BCard, BCardText, BRow, BCol, BButton, BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput,
    BFormGroup, BForm, BOverlay, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
    BInputGroupAppend, BInputGroupPrepend, BSpinner, BListGroup, BListGroupItem, BAlert

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
    components: {
        ValidationProvider, ValidationObserver, ToastificationContent,
        StatisticCardWithLineChart, vSelect,
        BCard, BCardText, BRow, BCol, BButton, BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput,
        BFormGroup, BForm, BOverlay, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BInputGroupPrepend, BSpinner, BListGroup, BListGroupItem, BAlert
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            testModal: false,
            trainModal: false,
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                { key: 'tag', label: 'tag', sortable: true },
                { key: 'patterns', label: 'patterns', sortable: true },
                { key: 'responses', label: 'responses' },
                { key: 'created_at', label: 'created at' },
                { key: 'action', label: '' },
            ],
            intents: [],
            intent: {
                id: null,
                tag: '',
                text: '',
                text2: '',
                patterns: [],
                responses: []
            },
            train: {
                language: 'en'
            },
            test: {
                message: '',
                response: '',
                response_text: '',
                speed: 50,
                indice: 0
            },
            patterns: 0,
            responses: 0,
            languages: [{name: 'English', code: 'en'}, {name: 'arabic', code: 'ar'}, {name: 'French', code: 'fr'}, {name: 'Spanish', code: 'es'}, {name: 'Russian', code: 'ru'}, {name: 'Hindi', code: 'hi'}, {name: 'German', code: 'de'}, {name: 'Farsi', code:'fa'}, {name: 'Japanese', code: 'ja'}, {name: 'Turkish', code: 'tr'}, {name: 'Chinese', code: 'zh'}, {name: 'Italian', code: 'it'}],
            required
        }
    },
    mounted() {
        this.getIntents()
        if (localStorage.getItem('intent_language') != null && localStorage.getItem('intent_language') != '' && localStorage.getItem('intent_language') != undefined){
            this.train.language = localStorage.getItem('intent_language')
        }
    },
    methods: {
        deleteResponse(id) {
            this.intent.responses = this.intent.responses.filter(function (item) { return item.id != id; });
        },
        appendResponse() {
            if (this.intent.text2 != ''){
                this.intent.responses.push({ id: this.intent.responses.length + 1, text: this.intent.text2 })
                this.intent.text2 = ''
            }
        },
        deletePattern(id) {
            this.intent.patterns = this.intent.patterns.filter(function (item) { return item.id != id; });
        },
        appendPattern() {
            if (this.intent.text != ''){
                this.intent.patterns.push({ id: this.intent.patterns.length + 1, text: this.intent.text })
                this.intent.text = ''
            }
        },
        editModal(intent) {
            this.intent.id = intent.id
            this.intent.tag = intent.tag
            this.intent.patterns = intent.patterns
            this.intent.responses = intent.responses
            this.addModalShow = true
        },
        handleOkAddModal(bvModalEvt) {
            this.loading = true
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.submitForm()
        },
        displayTestResponse(){
            if (this.test.indice < this.test.response_text.length) {
                this.test.response += this.test.response_text.charAt(this.test.indice);
                this.test.indice++;
                setTimeout(this.displayTestResponse, this.test.speed);
            }
        },
        async testModel(){
            await this.$refs.simpleRules3.validate().then(success => {
                if (success) {
                    const data = {
                        message: this.test.message,
                        brand: this.$store.state.auth.user.current_brand
                    }
                    axios.post('/intents/test/', data)
                    .then(response => {
                        this.test.indice = 0
                        this.test.response = ''
                        this.test.response_text = response.data.message
                        this.displayTestResponse()
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Warning !',
                                icon: 'AlertCircleIcon',
                                text: 'Something Went Wrong.',
                                variant: 'danger',
                            },
                        })
                        console.log(error);
                    })
                }
            })
        },
        async trainModel(){
            localStorage.setItem('intent_language', this.train.language)
            await this.$refs.simpleRules2.validate().then(success => {
                if (success) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'AI assistant training',
                            icon: 'RefreshCcwIcon',
                            text: 'The AI assistant Training in progress.',
                            variant: 'warning',
                        },
                    })
                    const data = {
                        language: this.train.language,
                        brand: this.$store.state.auth.user.current_brand
                    }
                    axios.post('/intents/train/', data)
                    .then(respone => {
                        console.log(respone);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'AI assistant trained',
                                icon: 'CheckIcon',
                                text: 'The AI assistant was trained successfully.',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Warning !',
                                icon: 'AlertCircleIcon',
                                text: 'Something Went Wrong.',
                                variant: 'danger',
                            },
                        })
                        console.log(error);
                    })
                }
            })
        },
        async submitForm() {
            await this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        tag: this.intent.tag.toLowerCase(),
                        items_pattern: this.intent.patterns,
                        items_response: this.intent.responses,
                        brand: this.$store.state.auth.user.current_brand
                    }
                    var action, endpoint;
                    if (this.intent.id != null) {
                        endpoint = `/intents/${this.intent.id}/`
                        action = 'patch'
                    } else {
                        endpoint = '/intents/'
                        action = 'post'
                    }
                    axios[action](endpoint, data)
                        .then((response) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Intent Saved',
                                    icon: 'CheckIcon',
                                    text: 'Intent was saved successfully.',
                                    variant: 'success',
                                },
                            })
                            this.loading = false
                            this.addModalShow = false
                            this.resetAddModal()
                            this.getIntents()
                            console.log(response);
                        })
                        .catch((error) => {
                            this.loading = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Warning !',
                                    icon: 'AlertCircleIcon',
                                    text: 'Something Went Wrong.',
                                    variant: 'danger',
                                },
                            })
                            JSON.stringify(error);
                        })
                } else {
                    setTimeout(() => this.loading = false, 500);
                }
            })
        },
        resetAddModal() {
            this.intent.id = null
            this.intent.tag = ''
            this.intent.text = ''
            this.intent.text2 = ''
            this.intent.patterns = []
            this.intent.responses = []
            this.addModalShow = false
        },
        deleteIntent(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/intents/${id}/`)
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'The intent has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.getIntents()
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
        async getIntents() {
            await axios.get(`/intents?intent_id=${this.$store.state.auth.user.current_intent}`)
                .then(response => {
                    console.log(response);
                    this.intents = response.data
                    this.totalRows = response.data.length
                    this.showLoader = false
                    this.patterns = 0
                    this.responses = 0
                    response.data.forEach(item => {
                        item.patterns.forEach(pt => {this.patterns += 1;})
                        item.responses.forEach(pt => {this.responses += 1;})
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>